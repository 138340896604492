<!-- @Description: 素材库 -->
<template>
  <div>
    <el-dialog title="素材管理" :visible.sync="dialogVisible" width="680px">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="图片" name="first"></el-tab-pane>
        <el-tab-pane label="图标" name="second"></el-tab-pane>
      </el-tabs>

      <div v-if="activeName == 'first'" class="flex pic-content">
        <div class="cont-left">
          <p class="f-black bg-white">图片分组</p>
          <el-tree :data="groupingData" :props="defaultProps"></el-tree>
        </div>
        <div class="cont-right">
          <p>
            <el-form v-model="form" ref="form" :inline="true" size="mini">
              <el-form-item label="图片名称">
                <el-input v-model="form.name" placeholder="图片名称"></el-input>
              </el-form-item>
              <el-form-item label="图片格式">
                <el-input v-model="form.format" placeholder="图片格式"></el-input>
              </el-form-item>
            </el-form>
          </p>
          <div class="flex flex-wrap row-left p13 text-center">
            <div v-for="(item, index) in picture" :key="index" class="h100 text-center m10">
              <div class="w80 h80 r5 picture-div" style="background: rgb(247, 248, 250);">
                <img :src="item.path" />
              </div>
              <div>{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="activeName == 'second'" class="flex pic-content">
        <!-- <div class="cont-left">
            <p style="color: #ffffff;background-color: #006aff;">系统图标</p>
          </div>
          <div class="cont-right">
            <p>选择图标</p>
            <div class="flex flex-wrap row-left p13 text-center right-con">
              <div v-for="(item, index) in icon" :key="index" class="w80 h80 text-center bg-grey">
                <i :class="item.path" style="font-size: 28px;"></i>
                <div>{{ item.name }}</div>
              </div>
            </div>

          </div> -->
        <el-tabs type="border-card">
          <el-tab-pane style="height: 450px;overflow-y: scroll;" label="Element-UI Icons">
            <div class="grid">
              <div v-for="(item,index) of elementIcons" :key="index" @click="handleClipboard(generateElementIconCode(item),$event)">
                <div class="icon-item" @click="generateElementIconCode(item)">
                  <i :class="'el-icon-' + item" />
                  <span>{{ item }}</span>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane style="height: 450px;overflow-y: scroll;" label="Ali Icons">
            <div class="grid">
              <div v-for="(item,index) of iconList" :key="index" @click="handleClipboard(generateIconCode(item),$event)">
                <div class="icon-item" @click="generateIconCode(item)">
                  <i :class="item.icon" style="font-size: 30px;"></i>
                  <span>{{ item.label }}</span>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <el-button v-if="activeName == 'first'" type="text" @click="uploadPic" class="upload-pic">上传图片</el-button>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false" size="mini">确 定</el-button>
      </span>
    </el-dialog>
    <uploadPic ref="uploadRef"></uploadPic>
  </div>
</template>

<script>
import clipboard from './clipboard'
// import svgIcons from './svg-icons'
import elementIcons from './element-icons'
import uploadPic from "../uploadPic";
export default {
  components: {
    uploadPic,
  },
  data () {
    return {
      // svgIcons,
      iconList:[

        {icon:'iconfont icon-medal',name:'icon-medal',label:'技能人才评选'},
        {icon:'iconfont icon-jiangzhang',name:'icon-jiangzhang',label:'技能人才评选'},
        {icon:'iconfont icon-fagui',name:'icon-fagui',label:'技能人才评选'},

        {icon:'iconfont icon-caiwuliushui',name:'icon-caiwuliushui',label:'财务管理'},
        {icon:'iconfont icon-jianzhu',name:'icon-jianzhu',label:'机构申请'},

        {icon:'iconfont icon-tongzhi',name:'icon-tongzhi',label:'通知公告'},
        {icon:'iconfont icon-ziliaoku',name:'icon-ziliaoku',label:'资料下载'},
        {icon:'iconfont icon-yingyong',name:'icon-yingyong',label:'应用中心'},
        {icon:'iconfont icon-fenzuguanli',name:'icon-fenzuguanli',label:'过程管理'},
        {icon:'iconfont icon-zhuanyeyukecheng',name:'icon-zhuanyeyukecheng',label:'专业申请'},
        {icon:'iconfont icon-zhufangbutiemingxi',name:'icon-zhufangbutiemingxi',label:'培训券'},
        {icon:'iconfont icon-quanxianpeizhi',name:'icon-quanxianpeizhi',label:'权限管理'},
        {icon:'iconfont icon-_xuesheng',name:'icon-_xuesheng',label:'学员管理'},
        {icon:'iconfont icon-jiaoshi',name:'icon-jiaoshi',label:'教师管理'},
        {icon:'iconfont icon-xunjian',name:'icon-xunjian',label:'培训巡检'},
        {icon:'iconfont icon-icon_icon_kechengshuju_x',name:'icon-icon_icon_kechengshuju_x',label:'教室管理'},
        {icon:'iconfont icon-shuji',name:'icon-shuji',label:'学习中心'},
        {icon:'iconfont icon-wentisousuo',name:'icon-wentisousuo',label:'培训问题核查'},
        {icon:'iconfont icon-xuexiao',name:'icon-xuexiao',label:'机构申请'},
        {icon:'iconfont icon-a-butie2',name:'icon-a-butie2',label:'非补贴班级'},
        {icon:'iconfont icon-a-butie1',name:'icon-a-butie1',label:'补贴性班级'},
        {icon:'iconfont icon-me_shipinjiankong',name:'icon-me_shipinjiankong',label:'设备管理'},

        {icon:'iconfont icon-jiankongshebei',name:'icon-jiankongshebei',label:'监控设备'},
        {icon:'iconfont icon-shujutongji',name:'icon-shujutongji',label:'数据统计'},
        {icon:'iconfont icon-shebeiguanli2',name:'icon-shebeiguanli2',label:'设备管理'},
        {icon:'iconfont icon-banjixuncha1',name:'icon-banjixuncha1',label:'班级巡查'},
        {icon:'iconfont icon-renshebumen',name:'icon-renshebumen',label:'人社部门'},
        {icon:'iconfont icon-hangyebumen',name:'icon-hangyebumen',label:'行业部门'},
        {icon:'iconfont icon-shouyegongzuotai',name:'icon-shouyegongzuotai',label:'首页工作台'},
        {icon:'iconfont icon-xunjianrenwu',name:'icon-xunjianrenwu',label:'巡查任务'},
        {icon:'iconfont icon-jigouxuncha',name:'icon-jigouxuncha',label:'机构巡查'},
        {icon:'iconfont icon-yunmouxunjian',name:'icon-yunmouxunjian',label:'云眸巡检'},
        {icon:'iconfont icon-zhuanye',name:'icon-zhuanye',label:'专业'},
        {icon:'iconfont icon-shijian',name:'icon-shijian',label:'217时间'},


        {icon:'iconfont icon-zidongxuncha',name:'icon-zidongxuncha',label:'自动巡查'},
        {icon:'iconfont icon-xunchadaiban',name:'icon-xunchadaiban',label:'巡查待办'},
        {icon:'iconfont icon-daibanshixiang',name:'icon-daibanshixiang',label:'待办事项'},
        {icon:'iconfont icon-dianjianjilu',name:'icon-dianjianjilu',label:'点检记录'},
        {icon:'iconfont icon-lunbojilu',name:'icon-lunbojilu',label:'轮播记录'},
        {icon:'iconfont icon-wentijilu',name:'icon-wentijilu',label:'问题记录'},
        {icon:'iconfont icon-jiaoshixuncha',name:'icon-jiaoshixuncha',label:'教室巡查'},
        {icon:'iconfont icon-banjixuncha',name:'icon-banjixuncha',label:'班级巡查'},
        {icon:'iconfont icon-xianxiaxuexiguocheng',name:'icon-xianxiaxuexiguocheng',label:'线下学习过程'},
        {icon:'iconfont icon-gongzuotai',name:'icon-gongzuotai',label:'工作台'},

        {icon:'iconfont icon-xianshangbanjiliebiao',name:'icon-xianshangbanjiliebiao',label:'线上班级列表'},
        {icon:'iconfont icon-shenqingjieye',name:'icon-shenqingjieye',label:'申请结业'},
        {icon:'iconfont icon-kaibanguanli',name:'icon-kaibanguanli',label:'开班管理'},
        {icon:'iconfont icon-peixunguanli',name:'icon-peixunguanli',label:'培训管理'},
        {icon:'iconfont icon-xianshangxuexijianguan',name:'icon-xianshangxuexijianguan',label:'线上学习监管'},
        {icon:'iconfont icon-guochengguanli',name:'icon-guochengguanli',label:'过程管理'},
        {icon:'iconfont icon-xiaoxiguanli',name:'icon-xiaoxiguanli',label:'消息管理'},
        {icon:'iconfont icon-chengjiluru',name:'icon-chengjiluru',label:'成绩录入'},

        {icon:'iconfont icon-shebeiliebiao',name:'icon-shebeiliebiao',label:'设备列表'},
        {icon:'iconfont icon-xianxiaxuexijianguan',name:'icon-xianxiaxuexijianguan',label:'线下学习监管'},
        {icon:'iconfont icon-tongjigailan',name:'icon-tongjigailan',label:'统计概览'},
        {icon:'iconfont icon-xueyuanliebiao',name:'icon-xueyuanliebiao',label:'学员列表'},
        {icon:'iconfont icon-xuexijihua',name:'icon-xuexijihua',label:'学习计划'},
        {icon:'iconfont icon-pingjiaguanli',name:'icon-pingjiaguanli',label:'评价管理'},
        {icon:'iconfont icon-yewuliucheng',name:'icon-yewuliucheng',label:'业务流程'},
        {icon:'iconfont icon-zhiyuanguanli',name:'icon-zhiyuanguanli',label:'职员管理'},

        {icon:'iconfont icon-xueyuanguanli',name:'icon-xueyuanguanli',label:'学员管理'},
        {icon:'iconfont icon-xuexipingtai',name:'icon-xuexipingtai',label:'学习平台'},
        {icon:'iconfont icon-tongji',name:'icon-tongji',label:'统计'},
        {icon:'iconfont icon-banjiguanli1',name:'icon-banjiguanli1',label:'班级管理'},
        {icon:'iconfont icon-butiezhengce',name:'icon-butiezhengce',label:'补贴政策'},
        {icon:'iconfont icon-butieshenhe',name:'icon-butieshenhe',label:'补贴审核'},
        {icon:'iconfont icon-butiexinxi',name:'icon-butiexinxi',label:'补贴信息'},
        {icon:'iconfont icon-zhuanyeliebiao',name:'icon-zhuanyeliebiao',label:'专业列表'},

        {icon:'iconfont icon-zhuanyeguanli',name:'icon-zhuanyeguanli',label:'专业管理'},
        {icon:'iconfont icon-xueyuanxiaoyan',name:'icon-xueyuanxiaoyan',label:'学员校验'},
        {icon:'iconfont icon-jigoujianguan',name:'icon-jigoujianguan',label:'机构监管'},
        {icon:'iconfont icon-jiaoshiliebiao1',name:'icon-jiaoshiliebiao1',label:'教室列表'},
        {icon:'iconfont icon-xueyuanshenhe',name:'icon-xueyuanshenhe',label:'学员审核'},
        {icon:'iconfont icon-jiaoshiguanli1',name:'icon-jiaoshiguanli1',label:'教室管理'},
        {icon:'iconfont icon-kaoshiguochengjianguan',name:'icon-kaoshiguochengjianguan',label:'考试过程监管'},
        {icon:'iconfont icon-banjiliebiao',name:'icon-banjiliebiao',label:'班级列表'},

        {icon:'iconfont icon-butieshenqing',name:'icon-butieshenqing',label:'补贴申请'},
        {icon:'iconfont icon-jigouguanli',name:'icon-jigouguanli',label:'机构管理'},
        {icon:'iconfont icon-jigoushenhe',name:'icon-jigoushenhe',label:'机构审核'},
        {icon:'iconfont icon-jiaoshiliebiao',name:'icon-jiaoshiliebiao',label:'教师列表'},
        {icon:'iconfont icon-jigouliebiao',name:'icon-jigouliebiao',label:'机构列表'},
        {icon:'iconfont icon-xiaoxizhongxin',name:'icon-xiaoxizhongxin',label:'消息中心'},
        {icon:'iconfont icon-zhanghuguanli',name:'icon-zhanghuguanli',label:'账户管理'},
        {icon:'iconfont icon-gongdan',name:'icon-gongdan',label:'工单'},

        {icon:'iconfont icon-shenqing',name:'icon-shenqing',label:'申请'},
        {icon:'iconfont icon-xinwenzixun',name:'icon-xinwenzixun',label:'新闻资讯'},
        {icon:'iconfont icon-gonggaoguanli',name:'icon-gonggaoguanli',label:'公告管理'},
        {icon:'iconfont icon-daoruyuangong',name:'icon-daoruyuangong',label:'导入员工'},
        {icon:'iconfont icon-jiaoseguanli',name:'icon-jiaoseguanli',label:'角色管理'},
        {icon:'iconfont icon-bumen',name:'icon-bumen',label:'部门'},
        {icon:'iconfont icon-daiban',name:'icon-daiban',label:'待办'},
        {icon:'iconfont icon-butiejiesuan',name:'icon-butiejiesuan',label:'补贴结算'},

        {icon:'iconfont icon-bangzhu',name:'icon-bangzhu',label:'帮助'},
        {icon:'iconfont icon-butieguanli',name:'icon-butieguanli',label:'补贴管理'},
        {icon:'iconfont icon-xitongshezhi',name:'icon-xitongshezhi',label:'设置'},
        {icon:'iconfont icon-banjiguanli',name:'icon-banjiguanli',label:'班级'},
        {icon:'iconfont icon-laoshiguanli',name:'icon-laoshiguanli',label:'教师'},
        {icon:'iconfont icon-shebeiguanli',name:'icon-shebeiguanli',label:'设备信息'},
        {icon:'iconfont icon-caiwuguanli',name:'icon-caiwuguanli',label:'财务管理'},
        {icon:'iconfont icon-quanxianguanli',name:'icon-quanxianguanli',label:'权限'},

        {icon:'iconfont icon-shouye',name:'icon-shouye',label:'首页'},
        {icon:'iconfont icon-jigoushenqing',name:'icon-jigoushenqing',label:'合作机构'},
        {icon:'iconfont icon-zhuanyeshenqing',name:'icon-zhuanyeshenqing',label:'专业'},
        {icon:'iconfont icon-daochu',name:'icon-daochu',label:'导出'},

        {icon:'iconfont icon-QRcode',name:'icon-QRcode',label:'二维码'},
        {icon:'iconfont icon-xiaoxi1',name:'icon-xiaoxi1',label:'消息'},
        {icon:'iconfont icon-wode-F',name:'icon-wode-F',label:'我的'},
        {icon:'iconfont icon-xiaoxi',name:'icon-xiaoxi',label:'消息'},
        {icon:'iconfont icon-bangzhu',name:'icon-bangzhu',label:'帮助'},
        {icon:'iconfont icon-xitongshezhi',name:'icon-xitongshezhi',label:'设置'},

      ],
      elementIcons,
      scon:'',
      iconactive:'ali',
      dialogVisible: false,
      activeName: 'first',
      form: {
        name: '',
        format: '',
      },
      groupingData: [{
        label: '一级 1',
        children: [{
          label: '二级 1-1',
          children: [{
            label: '三级 1-1-1'
          }]
        }]
      }, {
        label: '一级 2',
        children: [{
          label: '二级 2-1',
        }, {
          label: '二级 2-2',
        }]
      }],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      picture: [{
        name: 'xx1',
        path: 'https://wx3.sinaimg.cn/mw690/0070NSSfgy1hqa9j5i5ebj30u0140dnz.jpg'
      }, {
        name: 'xx2',
        path: 'https://ww2.sinaimg.cn/mw690/0070NSSfgy1hq39jbdyuwj31z41z4ng7.jpg'
      }, {
        name: 'xx3',
        path: 'https://wx4.sinaimg.cn/large/007fE4bAly4gqa3zx8as0j30u00jzmyb.jpg'
      }, {
        name: 'xx4',
        path: 'https://inews.gtimg.com/om_bt/O10C3cMUVfWPIV6QqLGYhlOHrhteBRqi0CSh2uvYNu6hAAA/641'
      }, {
        name: 'xx4',
        path: 'https://inews.gtimg.com/om_bt/O10C3cMUVfWPIV6QqLGYhlOHrhteBRqi0CSh2uvYNu6hAAA/641'
      }],
      icon: [{
        name: 'xx1',
        path: 'el-icon-eleme'
      }, {
        name: 'xx2',
        path: 'el-icon-eleme'
      }, {
        name: 'xx3',
        path: 'el-icon-eleme'
      }, {
        name: 'xx4',
        path: 'el-icon-eleme'
      }, {
        name: 'xx5',
        path: 'el-icon-eleme'
      }, {
        name: 'xx6',
        path: 'el-icon-eleme'
      }, {
        name: 'xx7',
        path: 'el-icon-eleme'
      }, {
        name: 'xx8',
        path: 'el-icon-eleme'
      }, {
        name: 'xx9',
        path: 'el-icon-eleme'
      }, {
        name: 'xx10',
        path: 'el-icon-eleme'
      }, {
        name: 'xx11',
        path: 'el-icon-eleme'
      }, {
        name: 'xx12',
        path: 'el-icon-eleme'
      }, {
        name: 'xx13',
        path: 'el-icon-eleme'
      }, {
        name: 'xx14',
        path: 'el-icon-eleme'
      }, {
        name: 'xx15',
        path: 'el-icon-eleme'
      }, {
        name: 'xx16',
        path: 'el-icon-eleme'
      }, {
        name: 'xx17',
        path: 'el-icon-eleme'
      }, {
        name: 'xx18',
        path: 'el-icon-eleme'
      }, {
        name: 'xx19',
        path: 'el-icon-eleme'
      }, {
        name: 'xx20',
        path: 'el-icon-eleme'
      }],
      state:{
        fontIconList: {
          ali: [],
          ele: [],
          awe: [],
          local: [],
        },
      },
      iconlist:[]
    }
  },
  mounted() {
  },
  methods: {
    generateIconCode(symbol) {
      console.log('symbol',symbol)
      return `<span class="${symbol.icon}"></span>`
    },
    generateElementIconCode(symbol) {
      return `<i class="el-icon-${symbol}" />`
    },
    handleClipboard(text, event) {
      clipboard(text, event)
    },
    open() {
      this.dialogVisible = true;
    },
    uploadPic() {
      this.uploadVisible = true;
      this.$refs["uploadRef"].open();
    },
    handleClick(e){
      this.iconactive = e.name
    },

  }
}
</script>

<style lang="scss" scoped>
  ::v-deep.icontab{
    .el-tabs__item.is-active{
      background: #006aff;
      color: #fff;
    }
  }
  .grid {
    /* position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)); */
    display: flex;
    flex-wrap: wrap;
  }

  .icon-item {
    margin: 20px;
    height: 85px;
    text-align: center;
    width: 100px;
    float: left;
    font-size: 30px;
    color: #24292e;
    cursor: pointer;
  }

  span {
    display: block;
    font-size: 16px;
    margin-top: 10px;
  }

  .disabled {
    pointer-events: none;
  }
.pic-content {
  width: 100%;
  height: 400px;
  overflow: auto;
  .cont-left {
    width: 130px;
    border-right: 1px solid #ccc;
    p {
      padding: 10px;
    }
  }
  .cont-right {
    width: calc(100% - 130px);
    p {
      color: #333333;
      padding: 10px;
      background: rgb(247, 248, 250);
    }
    .right-con {
      height: 364px;
      overflow-y: scroll;
      // -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }
      &::-webkit-scrollbar-button {
        display: none;
      }

    }
    .picture-div {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-width: 90%;
        max-height: 90%;
      }
    }
  }

}
.upload-pic {
  position: absolute;
}
</style>
