<!-- @Description: 上传图片 -->
<template>
  <el-dialog title="上传图片" :visible.sync="uploadVisible" width="680px">
    <el-form v-model="form" :inline="true">
      <el-row>
        <el-form-item label="上传方式" :rules="{ required: true, message: '必填项' }">
          <el-radio-group v-model="form.radio">
            <el-radio label="0">本地上传</el-radio>
            <el-radio label="1">扫码上传</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="所在分组" :rules="{ required: true, message: '必填项' }">
          <el-select v-model="value" filterable placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="本地图片" :rules="{ required: true, message: '必填项' }">
          <el-upload
            class="avatar-uploader"
            action="https://jsonplaceholder.typicode.com/posts/"
            :show-file-list="false"
            :on-remove="handleRemove">

<!--            :on-success="handleAvatarSuccess"-->


            <img v-if="form.imageUrl" :src="form.imageUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="图片名称" :rules="{ required: true, message: '必填项' }">
          <el-input v-model="form.name" placeholder="请输入图片名称"></el-input>
        </el-form-item>
      </el-row>
    </el-form>

    <!-- 二维码显示 -->
    <!-- <qrcode value="扫描上传" :options="{ width: 200 }"></qrcode> -->
    <!-- 文件上传按钮 -->
    <!-- <el-input type="file" @change="handleFileChange" /> -->

    <span slot="footer" class="dialog-footer">
      <el-button @click="uploadVisible = false" size="mini">取 消</el-button>
      <el-button type="primary" @click="uploadVisible = false" size="mini">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
// import { qrcode } from 'vue-qrcode';

export default {
  components: {
    // qrcode,
  },
  data () {
    return {
      uploadVisible: false,
      form: {
        mode: '0',
        name: '',
        imageUrl: '',
      },
      value:'',
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }],
    }
  },
  methods: {
    open() {
      this.uploadVisible = true;
    },
    handleRemove() {},
    // handleFileChange() {},
  }
}
</script>

<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #DCDFE6;
  border-radius: 6px;
  cursor: pointer;
//   position: relative;
//   overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>
