<!--
 * @Description: 商城导航
 * @Autor: WangYuan
 * @Date: 2021-06-10 19:31:01
 * @LastEditors: WangYuan
 * @LastEditTime: 2022-08-11 11:00:51
-->
<template>
  <div class="wrap">
    <!-- 导航展示 -->
    <div style="padding-right: 360px">
      <phone-ctn>
        <div class="edit">
          <nav-bar v-bind="navigation" :disabled="true" :navStyle="form.footStyle"></nav-bar>
        </div>
      </phone-ctn>
    </div>

    <!-- 导航配置 -->
    <config-ctn title="底部导航">
      <config-wrap>
        <el-button @click="openDialog">素材库</el-button>
        <div class="mb15 f16" style="font-size: 14px!important;margin:6px 0 0 12px;">模板选择</div>
        <el-form :model="form" ref="form" label-width="80px">
          <el-form-item label="样式选择" prop="name">
            <el-select v-model="form.footStyle" placeholder="请选择样式" size="small" style="width: 180px;margin-left: 60px;">
              <el-option label="标准版" value="0"></el-option>
              <el-option label="主图标版" value="1"></el-option>
              <el-option label="悬浮窗版" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="nav">
          <div class="mt10 mb15 f12 f-grey">可配置 1 至 5 个导航</div>
          <div class="mt10 mb15 f12 f-red">主页导航不可配置</div>
          <draggable v-model="bottomNavigation.list">
            <div v-for="(item, index) in bottomNavigation.list" v-if="index > 0" :key="item.id">
              <!--              <div class="nav-item" v-if="!item.jump||(item.jump&&item.jump.id!='my')">-->
              <div class="nav-item">
                <div v-if="bottomNavigation.list.length > 2" class="nav-delete" @click="bottomNavigation.list.splice(index, 1)">
                  <i class="el-icon-close" />
                </div>

                <div class="flex">
                  <div class="flex f13">
                    <icon-select v-model="item.icon"></icon-select>
                  </div>

                  <div>
                    <el-input v-model="item.text" size="small" maxlength="4" show-word-limit style="width: 170px;margin-bottom: 10px;"></el-input>

                    <div>
                      <jump-select v-model="item.jump"></jump-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </draggable>

          <div v-if="bottomNavigation.list.length < 5" class="nav-add" @click="add">新增导航</div>
        </div>

        <div class="mt25 intervalLine"></div>
        <div class="mb15 f16 mt20">应用页面</div>
        <div class="open-page-decription">默认在以下页面展示</div>
        <el-checkbox-group v-model="openPageList" style="z-index: -10!important;">
          <el-checkbox label="店铺主页"></el-checkbox>
          <el-checkbox label="个人中心"></el-checkbox>
          <el-checkbox label="商品搜索"></el-checkbox>
        </el-checkbox-group>

        <div class="mt25 intervalLine"></div>
        <div class="mb15 f16 mt20">更多设置</div>
        <el-form :model="form" style="margin-bottom: 90px;">
          <el-form-item label="导航图标色" prop="iconColor">
            <el-radio-group v-model="form.iconColor" size="small" style="margin-left: 30px;">
              <el-radio-button label="0" value="0">自定义模式</el-radio-button>
              <el-radio-button label="1" value="1">与店铺风格一致</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </config-wrap>
    </config-ctn>

    <material ref="mater"></material>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import IconSelect from "@/components/IconSelect";
import material from "@/components/material";
import { mapGetters } from "vuex";

export default {
  components: {
    NavBar,
    IconSelect,
    material,
  },
  data() {
    return {
      openPageList: ['店铺主页', '个人中心', '商品搜索'],
      form: {
        footStyle: '0',
        iconColor: '1',
      },
      navigation: {},
      bottomNavigation: []
    }
  },

  computed: {
    ...mapGetters(["project"]),
  },
  watch: {
    "form": {
      handler(val) {
        let {label,styles,list} = {...this.bottomNavigation}
        val = {label,styles,list,...val}
        this.$store.commit('setGlobalBottom', val)
      },
      deep: true,
    },
    "project": {
      handler() {
        this.navigation = JSON.parse(this.project.configEntity.bottomNavigation)
      },
      deep: true,
    },
    "bottomNavigation.list": {
      handler(val) {
        console.log('bottomNavigation', val)
          val = { ...this.bottomNavigation, ...this.form }
        this.$store.commit('setGlobalBottom', val)
      },
      deep: true,
    }
  },
  mounted() {
    console.log('bottom-----', this.project)
    this.getInit()
  },
  methods: {
    getInit() {
      this.bottomNavigation = JSON.parse(this.project.configEntity.bottomNavigation)
      this.navigation = JSON.parse(this.project.configEntity.bottomNavigation)
    },
    add() {


      this.bottomNavigation.list.push({ value: "" });
      console.log("==this.bottomNavigation.list==:",this.bottomNavigation.list);

    },
    openDialog() {
      this.$refs["mater"].open();
    },
  },
};
</script>

<style lang="scss" scoped>
.edit {
  display: flex;
  align-items: flex-end;
  width: 100%;
  min-height: 603px
    /*no*/
  ;
  background: #f7f8fa;
}

.el-input--small .el-input__inner {
  height: 28px;
}

.intervalLine {
  width: 100%;
  border-bottom: 1px solid #d8d8d8;
}

.open-page-decription {
  color: #999;
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 18px;
}
</style>
